import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';

import Layout from '../../components/Layout';

import './error-page.scss';

const ErrorContent = injectIntl(({ responseCode }) => {
  const homePageLink = {
    'home-page': (
      <Link to="/">
        <FormattedMessage id={responseCode === '500' ? 'pages.error-page.500.home-page' : 'common.home-page'} />
      </Link>
    ),
  };
  return (
    <Layout>
      <div className="page-content-wrapper error-page">
        <section className="page-content grid-y">
          {responseCode === '500' && <i className="oi-caution outline" />}
          <h1>
            <FormattedMessage id={`pages.error-page.${responseCode}.title`} />
          </h1>
          <p>
            <FormattedMessage id={`pages.error-page.${responseCode}.cta`} values={homePageLink} />
            <br />
            <FormattedMessage id={`pages.error-page.${responseCode}.extra`} values={homePageLink} />
          </p>
        </section>
      </div>
    </Layout>
  );
});

ErrorContent.displayName = 'ErrorContent';

ErrorContent.propTypes = {
  responseCode: PropTypes.string.isRequired,
};

export default ErrorContent;
